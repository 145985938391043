<template>
    <div id="modulr-verification" class="h-full">
        <!---------------- Start Modulr Verification Navbar --------------->
        <div
            class="flex items-center justify-between fixed top-0 p-[15px] left-0 right-[0] w-full z-[9999]"
        >
            <div
                class="w-[35px] h-[25px] flex items-center"
                :style="[
                    modulrStep === 1
                        ? { opacity: 0, 'pointer-events': 'none' }
                        : {},
                ]"
            >
                <button v-if="modulrStep === 2" class="navbar_button_requests">
                    <img
                        src="../../assets/images/arrowBack.png"
                        width="25"
                        @click="modulrStep = 1"
                        alt=""
                    />
                </button>
                <button v-if="modulrStep === 3" class="navbar_button_requests">
                    <img
                        src="../../assets/images/arrowBack.png"
                        width="25"
                        @click="modulrStep = 2"
                        alt=""
                    />
                </button>
                <button v-if="modulrStep === 4" class="navbar_button_requests">
                    <img
                        src="../../assets/images/arrowBack.png"
                        width="25"
                        @click="modulrStep = 1"
                        alt=""
                    />
                </button>
            </div>

            <span
                :style="[
                    modulrStep === 0 || modulrStep === 6
                        ? { opacity: 0, 'pointer-events': 'none' }
                        : {},
                ]"
                class="flex flex-row justify-center items-center"
            >
                <RouterLink
                replace
                    to="/guardian"
                    class="appNavbar__listLink navbar_button_requests"
                    style="font-size: 0px"
                >
                    <img
                        src="../../assets/images/close.png"
                        class="object-cover w-[25px] h-[25px] p-[2px]"
                        alt=""
                    />
                </RouterLink>
            </span>

            <span
                v-if="modulrStep === 0"
                class="flex flex-row justify-center items-center"
            >
                <RouterLink to="/guardian" replace class="text-xs">
                    Not now
                </RouterLink>
            </span>
        </div>
        <!---------------- End Modulr Verification Navbar --------------->
        <div class="modulr-step-1 h-full grid flex-col" style="grid-template-rows: 1fr 50px;">
            <div
            style="grid-row: 1;"
                :class="
                    modulrStep === 0 ||
                    modulrStep === 3 ||
                    modulrStep === 5 ||
                    modulrStep === 6
                        ? 'flex-1 flex items-center'
                        : 'flex-1'
                "
            >
                <!------ Start progress steps --------------->
                <div
                    v-if="
                        modulrStep != 0 &&
                        modulrStep != 6 &&
                        modulrStep != 5 &&
                        modulrStep != 3
                    "
                    class="progress"
                >
                    <div
                        id="1"
                        class="step"
                        :class="
                            modulrStep === 1 || modulrStep === 4
                                ? 'current'
                                : {}
                        "
                    >
                        <span />
                    </div>
                    <div />
                    <div
                        id="2"
                        class="step"
                        :class="modulrStep === 2 ? 'current' : {}"
                    >
                        <span />
                    </div>
                    <div />
                    <div
                        id="3"
                        class="step"
                        :class="modulrStep === 3 ? 'current' : {}"
                    >
                        <span />
                    </div>
                    <div />
                    <div
                        id="5"
                        class="step"
                        :class="modulrStep === 5 ? 'current' : {}"
                    >
                        <span />
                    </div>
                </div>
                <!------ End progress steps --------------->

                <template v-if="modulrStep != 5">
                    <h1
                        v-if="
                            modulrStep == 1 ||
                            modulrStep == 4 ||
                            modulrStep == 2
                        "
                        class="text-left text-[1.4rem] font-bold text-primary"
                    >
                        Account verification,
                    </h1>
                    <p
                        v-if="modulrStep == 1 || modulrStep == 4"
                        class="text-left text-[1.35rem] font-medium mb-[30px] text-black"
                    >
                        please enter your address
                    </p>
                    <p
                        v-if="modulrStep == 2"
                        class="text-left text-[1.35rem] font-medium mb-[30px] text-black"
                    >
                        please enter your details
                    </p>
                </template>

                <!------------------ Start Step 0 ------------------->
                <div v-if="modulrStep === 0">
                    <img
                        src="../../assets/images/step-0.svg"
                        class="mb-5 w-[80px]"
                        alt="step-0"
                    />
                    <h1
                        class="text-left text-[1.4rem] font-bold text-primary leading-tight"
                    >
                        To add real money to <br />Sonik Pocket,
                    </h1>
                    <p
                        class="text-left text-[1.35rem] font-medium mb-3 text-black"
                    >
                        let us quickly verify<br />
                        your personal details
                    </p>
                    <p class="text-[#909090] text-sm">
                        We must ask you for this information as part of the
                        regulations in the United Kingdom.
                    </p>

                    <p class="text-xs mt-8 text-[#909090]">FCA Approved</p>
                </div>

                <!------------------ Start Step 1 ------------------->
                <div v-if="modulrStep === 1">
                    <AppInput
                        v-model.number="modulr.houseNumber"
                        name="House / Flat Number"
                        label="house-number"
                        required
                    />
                    <AppInput
                        v-model="modulr.postcode"
                        name="Postcode"
                        label="postcode"
                        required
                        :has-loader="isAddressLoading"
                        @input="search"
                    />
                    <div
                        v-if="
                            showSelectAddressInput &&
                            modulr.houseNumber != null &&
                            selectAddresses.length > 0
                        "
                    >
                        <div
                            class="mb-[10px] self-start text-secondary text-[12px] mt-[20px] font-semibold"
                        >
                            Select your address
                        </div>
                        <div class="addressTypeHolder h-[50px] w-full">
                            <select
                                v-model="modulr.fullAddress"
                                class="addressTypeSelect h-[48px] w-full text-sm text-[#464a4c]"
                                @change="selectedAddress"
                            >
                                <option selected="selected" value="" disabled>
                                    Select your address
                                </option>
                                <option
                                    v-for="address in selectAddresses"
                                    :key="address.place_id"
                                    :value="address"
                                >
                                    {{ address.formatted_address }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <!------------------ End Step 1 ------------------->

                <!------------------ Start Step 2 ------------------->
                <div v-if="modulrStep === 2">
                    <AppInput
                        v-model="modulr.birthdate"
                        name="Date of Birth"
                        label="birthdate"
                        required
                        type="date"
                        style="-webkit-appearance: none"
                    />

                    <AppInput
                        v-model="modulr.phone"
                        name="Phone Number"
                        label="phone-number"
                        required
                        :max="11"
                    />
                </div>
                <!------------------ End Step 2 ------------------->

                <!------------------ Start Step 3 ------------------->
                <div v-if="modulrStep === 3">
                    <img
                        src="../../assets/images/step-3.svg"
                        class="mb-5 w-[80px]"
                        alt="step-3"
                    />
                    <h1 class="text-left text-[1.4rem] font-bold text-primary">
                        Upload a copy of your ID
                    </h1>

                    <p class="text-[#909090] text-sm mb-5 mt-8">
                        Types of ID which are accepted are:
                        <ul>
                          <li class="font-bold">Passport</li>
                          <li class="font-bold">Driver’s License</li>
                          <li class="font-bold">National Identity Card</li>
                          <li class="font-bold">Residence Permit Card</li>
                        </ul>
                    </p>

                    <p class="text-[#909090] text-sm mb-12">
                        We need proof of your ID to verify your identity. Please take a clear picture of the front of this document.
                    </p>

                    <label
                        for="proof-of-id"
                        class="font-bold text-[#9E9E9E] text-base ml-2"
                        >Upload Proof of ID</label
                    >
                    <div
                        id="proof-of-id"
                        class="border-dashed border-4 rounded-xl p-6 border-[#E5E5E5] flex items-center justify-center mt-2"
                        @click="$refs.proofId.click()"
                    >
                        <img
                            src="../../assets/images/upload-icon.svg"
                            class="w-[30px]"
                            alt="upload-icon"
                        />
                    </div>

                    <input
                        ref="proofId"
                        autocomplete="off"
                        type="file"
                        accept="image/*"
                        required="required"
                        class="hidden"
                        @change="handleIDUpload"
                    />

                    <div
                        class="mt-3 rounded-lg w-full"
                    >
                        <img
                            v-if="uploadIdPreview"
                            :src="uploadIdPreview"
                            class="object-contain"
                            alt="upload-preview"
                        />
                    </div>
                </div>

                <!------------------ Start Step 3.2 ------------------->
                <div v-if="modulrStep === 5">
                    <img
                        src="../../assets/images/step-5.svg"
                        class="mb-5 w-[80px]"
                        alt="step-3"
                    />
                    <h1 class="text-left text-[1.4rem] font-bold text-primary">
                        Upload a copy of your <br/> Address Info
                    </h1>
                    <p class="text-[#909090] text-sm mb-5 mt-8">
                        Types of ID which are accepted are:
                        <ul>
                          <li class="font-bold">Mobile bill</li>
                          <li class="font-bold">Bank Statement</li>
                          <li class="font-bold">Council Tax</li>
                        </ul>
                    </p>


                    <p class="text-[#909090] text-sm mb-12">
                        We need proof of your address  to verify your identity. Please take a clear picture of the front of this document.
                    </p>

                    <label
                        for="proof-of-id"
                        class="font-bold text-[#9E9E9E] text-base ml-2"
                        >Upload Proof of Address</label
                    >
                    <div
                        id="proof-of-id"
                        class="border-dashed border-4 rounded-xl p-6 border-[#E5E5E5] flex items-center justify-center mt-2"
                        @click="$refs.proofAddress.click()"
                    >
                        <img
                            src="../../assets/images/upload-icon.svg"
                            class="w-[30px]"
                            alt="upload-icon"
                        />
                    </div>

                    <input
                        ref="proofAddress"
                        autocomplete="off"
                        type="file"
                        accept="image/*"
                        required="required"
                        class="hidden"
                        @change="handleAddressUpload"
                    />

                    <div
                        class="mt-3 rounded-lg w-full"
                    >
                        <img
                            v-if="uploadAddressPreview"
                            :src="uploadAddressPreview"
                            class="object-contain"
                            alt="upload-preview"
                        />
                    </div>
                </div>

                <!------------------ Start Step 4 ------------------->
                <div v-if="modulrStep === 4">
                    <AppInput
                        v-model="modulr.houseNumber"
                        name="Number"
                        label="address-number"
                        type="text"
                        required
                    />
                    <AppInput
                        v-model="modulr.street"
                        name="Street"
                        label="address-street"
                        type="text"
                        required
                    />
                    <AppInput
                        v-model="modulr.town"
                        name="Town"
                        label="address-town"
                        type="text"
                        required
                    />
                    <AppInput
                        v-model="modulr.postcode"
                        name="Postcode"
                        label="address-postcode"
                        type="text"
                        required
                    />
                </div>
                <!------------------ Start Step 4 ------------------->

                <!------------------ Start Step 5 ------------------->
                <div v-if="modulrStep === 6">
                    <img
                        src="../../assets/images/step-0.svg"
                        class="mb-5 w-[80px]"
                        alt="step-0"
                    />
                    <h1
                        class="text-left text-[1.4rem] font-bold text-primary leading-tight"
                    >
                        Account Verification <br />
                        Complete!
                    </h1>
                    <p
                        class="text-left text-[1.35rem] font-medium mb-3 text-black"
                    >
                        It may take up to 2 hours <br />
                        for the verification process <br />
                        to be complete
                    </p>
                    <p class="text-[#909090] text-sm">
                        Dont worry! We’ll send you an email to keep <br />
                        you updated!
                    </p>
                </div>
                <!------------------ Start Step 5 ------------------->
            </div>

            <div
                v-if="modulrStep === 5"
                class="flex items-center justify-center"
                style="grid-row: 2;"
            >
                <button
                    v-if="modulr.addressFile != null && modulr.idFile != null"
                    class="bg-primary rounded-full text-white px-12 py-3 font-open-sans order-1"
                    @click="uploadNow"
                >
                    Upload Now!
                </button>
                <button
                    v-else
                    class="bg-disabled rounded-full text-white px-12 py-3 font-open-sans order-1"
                >
                    Upload Now!
                </button>
            </div>

            <div
                v-if="modulrStep === 4"
                class="flex justify-between items-center"
                style="grid-row: 2;"
            >
                <button
                    v-if="
                        modulr.houseNumber != null &&
                        modulr.street != null &&
                        modulr.town &&
                        modulr.postcode
                    "
                    class="bg-primary rounded-full text-white px-12 py-3 font-open-sans order-1"
                    @click="modulrStep = 2"
                >
                    Continue
                </button>
                <button
                    v-else
                    class="bg-disabled rounded-full text-white px-12 py-3 font-open-sans order-1"
                    disabled
                >
                    Continue
                </button>
                <div
                    v-if="modulrStep === 4"
                    class="manuallyAddress"
                    @click="modulrStep = 1"
                >
                    Find Address?
                </div>
            </div>

            <div
                v-if="modulrStep === 1"
                class="flex justify-between items-center"
                style="grid-row: 2;"
            >
                <button
                    v-if="
                        modulr.houseNumber != null &&
                        modulr.postcode != null &&
                        modulr.fullAddress != null
                    "
                    class="bg-primary rounded-full text-white px-12 py-3 font-open-sans order-1"
                    @click="modulrStep = 2"
                >
                    Continue
                </button>
                <button
                    v-else
                    class="bg-disabled rounded-full text-white px-12 py-3 font-open-sans order-1"
                    disabled
                >
                    Continue
                </button>
                <div
                    v-if="modulrStep === 1"
                    class="text-primary text-xs font-semibold text-left order-0"
                    @click="manuallyAddress(4)"
                >
                    Enter Address <br />
                    Manually?
                </div>
            </div>

            <div
                v-if="modulrStep === 2"
                class="flex justify-end items-center"
                style="grid-row: 2;"
            >
                <button
                    v-if="modulr.birthdate != null && modulr.phone != null"
                    class="bg-primary rounded-full text-white px-12 py-3 font-open-sans order-1"
                    @click="modulrStep = 3"
                >
                    Continue
                </button>
                <button
                    v-else
                    class="bg-disabled rounded-full text-white px-12 py-3 font-open-sans order-1"
                    disabled
                >
                    Continue
                </button>
            </div>

            <div
                v-if="modulrStep === 0"
                class="flex justify-end items-center"
                style="grid-row: 2;"
            >
                <button
                    class="bg-primary rounded-full text-white px-12 py-3 font-open-sans order-1"
                    @click="modulrStep = 1"
                >
                    Continue
                </button>
            </div>

            <div
                v-if="modulrStep === 3"
                class="flex justify-end items-center"
                style="grid-row: 2;"
            >
                <button
                    class="bg-primary rounded-full text-white px-12 py-3 font-open-sans order-1"
                    @click="modulrStep = 5"
                >
                    Continue
                </button>
            </div>

            <div
                v-if="modulrStep === 6"
                class="flex justify-end items-center"
                style="grid-row: 2;"
            >
                <button
                    class="bg-primary rounded-full text-white px-12 py-3 font-open-sans order-1"
                    @click="$router.replace('/guardian')"
                >
                    Continue
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { mapActions } from "vuex";
import AppInput from "../../components/AppInput";
export default {
    components: {
        AppInput,
    },
    data() {
        return {
            modulrStep: 0,
            isAddressLoading: false,
            showSelectAddressInput: false,
            selectAddresses: [],
            uploadAddressPreview: "",
            uploadIdPreview: "",
            modulr: {
                houseNumber: null,
                postcode: null,
                street: null,
                town: null,
                birthdate: null,
                phone: null,
                addressFile: null,
                idFile: null,
                fullAddress: null,
            },
        };
    },
    created() {
        this.$store.dispatch("fetchUser");
    },
    methods: {
        ...mapActions(["TOAST"]),
        getRefs() {
            console.log(this.$refs.proofId.$el);
        },
        uploadNow() {
            const {
                houseNumber,
                postcode,
                street,
                town,
                birthdate,
                phone,
                addressFile,
                idFile,
            } = this.modulr;

            var headers = {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            };

            let formData = new FormData();
            formData.append("post_town", town);
            formData.append("post_code", postcode);
            formData.append("address", street);
            formData.append("phone", phone);
            formData.append("date_of_birth", birthdate);
            formData.append("house_number", houseNumber);
            formData.append("proof_of_address", addressFile);
            formData.append("proof_of_ID", idFile);

            const token = localStorage.getItem("guardian_token");
            const userId = localStorage.getItem("guardian_user_id");

            axios
                .post(
                    `modulr/send-validation?token=${token}&userId=${userId}`,
                    formData,
                    headers
                )
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        this.TOAST(response.data.message);
                        this.modulrStep = 6;
                        this.modulr.houseNumber = null;
                        this.modulr.postcode = null;
                        this.modulr.street = null;
                        this.modulr.town = null;
                        this.modulr.birthdate = null;
                        this.modulr.phone = null;
                        this.modulr.addressFile = null;
                        this.modulr.idFile = null;
                        this.modulr.fullAddress = null;
                    } else {
                        this.TOAST(response.data.message);
                    }
                })
                .catch((error) => {
                    this.TOAST(error.message);
                });
        },
        manuallyAddress(step) {
            this.modulr.houseNumber = null;
            this.modulr.postcode = null;
            this.modulr.street = null;
            this.modulr.town = null;
            this.modulr.birthdate = null;
            this.modulr.phone = null;
            this.modulr.addressFile = null;
            this.modulr.idFile = null;
            this.modulr.fullAddress = null;
            this.modulrStep = step;
        },
        selectedAddress() {
            // console.log(this.modulr.fullAddress);
            //   console.log("full address");
            this.modulr.street =
                this.modulr.fullAddress.address_components.find(
                    (type) => type.types == "route"
                ).short_name;

            this.modulr.town = this.modulr.fullAddress.address_components.find(
                (type) => type.types == "postal_town"
            ).long_name;

            this.modulr.postcode =
                this.modulr.fullAddress.address_components.find(
                    (type) => type.types == "postal_code"
                ).long_name;

            //   console.log(this.modulr);
        },
        handleAddressUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            this.modulr.addressFile = files[0];
            this.uploadAddressPreview = URL.createObjectURL(files[0]);
        },
        handleIDUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            this.modulr.idFile = files[0];
            this.uploadIdPreview = URL.createObjectURL(files[0]);
        },
        search: _.debounce(function (input) {
            this.isAddressLoading = true;
            axios
                .post("/googleAddressPostalCode", {
                    postalCode: input,
                    token: "Sonik12345@-()kskhuii90u0iijhbasfj092h9oshdfsdbiuf0-j02",
                })
                .then((response) => {
                    this.selectAddresses = [];
                    this.modulr.fullAddress = null;
                    if (
                        response.data.results.length > 0 &&
                        response.data.results.find(
                            (x) => x.formatted_address != "United Kingdom"
                        )
                    ) {
                        this.selectAddresses = response.data.results;
                        this.showSelectAddressInput = true;
                    }
                    this.isAddressLoading = false;
                })
                .catch((error) => {
                    this.isAddressLoading = false;
                    throw error;
                });
        }, 1000),
    },
};
</script>

<style lang="scss" scoped>
.progress {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 45px;
    margin-bottom: 30px;
}
.progress div.step {
    position: relative;
    max-width: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid white;
}
.progress div.step span {
    display: block;
    text-align: center;
}
.progress div.step span:nth-of-type(1) {
    position: relative;
    height: 16px;
    width: 16px;
    list-style-type: none;
    border-radius: 50%;
    border: 4px solid #dddddd;
    background: #dddddd;
    text-align: center;
    color: #fff;
    line-height: 30px;
    transition: background-color 150ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.progress div.step span:nth-of-type(2) {
    color: #dddddd;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    white-space: nowrap;
    text-align: center;
}
.progress div:not(.step) {
    margin-top: 12px;
    height: 3px;
    width: calc(100% / 3);
    background: #dddddd;
    border: none;
    border-radius: 5px;
    transition: background-color 150ms ease;
}
.progress div:first-of-type:before {
    content: none;
}
.progress div.current {
    color: #fff;
}
.progress div:not(.step).current {
    background: var(--main);
}
.progress div.step.current span:nth-of-type(2) {
    color: var(--main);
}
.progress div.step.current span:first-child {
    background: var(--main);
    border-color: var(--main);
    color: #fff;
}

.addressTypeHolder {
    border: 1px solid #d8d8d8;
    background: #fafafa;
    box-sizing: border-box;
    border-radius: 15px;
    outline: none;
    padding: 0px 20px;
    font-size: 18px;
    height: 50px;
    width: 100%;
}
.addressTypeSelect {
    border: none;
    background: #fafafa;
    height: 48px;
    width: 100%;
    font-size: 0.9rem;
    color: rgb(70, 74, 76);
    -webkit-appearance: auto;
    appearance: auto;

    option {
        -webkit-appearance: auto;
        appearance: auto;
    }
}

.modulrButton {
    background: var(--main);
    border: none;
    padding: 15px;
    color: white;
    border-radius: 15px;
    width: 100%;
    font-weight: 600;
}

.manuallyAddress {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    color: var(--main);
    font-weight: 600;
}
</style>
